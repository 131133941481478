import MenuBar from "../../components/Menu/MenuBar";
import { ReactComponent as GooglePlayIcon } from "../../assets/GooglePlayIcon.svg";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { baseUrl } from "../../config/Environment";

const HomePage = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showField, setShowField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleRequestButton = async () => {
    if (!showField) {
      return setShowField(true);
    }

    if (!validateEmail(email)) {
      setErrorMsg("Please enter a valid email.");
      return;
    }

    setErrorMsg("");
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/tester/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, platform: "android" }),
      });
      console.log(response);
      console.log(await response.json());

      if (response.ok) {
        setIsSent(true);
      } else {
        setErrorMsg("Failed to send request. Please try again.");
      }
    } catch (error) {
      setErrorMsg("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div className="w-full flex flex-col h-screen overflow-y-scroll no-scrollbar">
      {/* Main Content */}
      <div className="w-full flex flex-col relative min-h-screen">
        {/* Background */}
        <div className="fixed w-full h-[100vh] bg-bg z-[-2]"></div>
        {/* Blurred Circle */}
        <div className="absolute left-[-50%] top-[-25%] w-[100vw] h-[100vh] bg-primary rounded-full opacity-20 blur-[200px] z-[-1]"></div>
        {/* Menu Bar */}
        <MenuBar />
        <div className="mt-28"></div>
        {/* Main Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 flex-grow">
          <div className="flex flex-col px-12">
            <h1 className="text-6xl text-white font-semibold">Fitwiz</h1>
            <div className="mt-4"></div>
            <p className="text-4xl text-white">
              Your go-to platform for fitness related events
            </p>
            <div className="mt-8"></div>
            {isSent ? (
              <p className="text-green-400 text-xl">You'll be notified soon!</p>
            ) : (
              <div className="flex gap-1">
                {showField && (
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`bg-white px-4 py-2 w-80 rounded-lg outline-none transition-all`}
                    disabled={isLoading}
                  />
                )}
                <button
                  className={`text-xl text-white bg-primary ${
                    showField ? "px-4" : "px-6"
                  } py-2 rounded-lg flex items-center gap-2 h-12`}
                  onClick={handleRequestButton}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Oval
                      visible={true}
                      width={20}
                      height={20}
                      strokeWidth={6}
                      color="#FFFFFF"
                      ariaLabel="oval-loading"
                    />
                  ) : (
                    <>
                      <GooglePlayIcon />
                      {!showField && <p>Request App Access</p>}
                    </>
                  )}
                </button>
              </div>
            )}
            {errorMsg && <p className="text-red-500 mt-2">{errorMsg}</p>}
          </div>
        </div>
      </div>

      {/* Footer Links */}
      <div className="text-center py-4">
        <a href="/privacy-policy" className="text-white mr-4">
          Privacy Policy
        </a>
        <a href="/terms-and-conditions" className="text-white">
          Terms & Conditions
        </a>
      </div>
    </div>
  );
};

export default HomePage;
