import React from "react";
import MenuItem from "./MenuItem";

const MenuBar = () => {
  return (
    <div className="w-full bg-red flex gap-10 py-8 px-12">
      <MenuItem title="HOME" href="/" isActive />
      <MenuItem title="CONTACT" href="/contact" />
    </div>
  );
};

export default MenuBar;
