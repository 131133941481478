import React from "react";
import { Link, NavLink } from "react-router-dom";

interface MenuItemProps {
  title: string;
  href: string;
  isActive?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, href, isActive }) => {
  return (
    <div>
      <a
        href={href}
        className={`text-2xl ${
          isActive ? "text-white" : "text-white/[.50]"
        } hover:text-white`}
      >
        {title}
      </a>
    </div>
  );
};

export default MenuItem;
