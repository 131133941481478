import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-bg">
      <div className="flex flex-col items-center">
        <h1 className="text-9xl text-white">404</h1>
        <p className="text-2xl text-white">Page not found</p>
        <Link
          to="/"
          className="text-xl p-4 text-white bg-blue-600 rounded-lg mt-6"
        >
          Go back to home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
